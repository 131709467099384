import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import {} from "@stripe/stripe-js";
import { type SubscriptionModel } from "../../models/stripe.model";
export interface AccountApiKeys {
    openAi: {
        apiKey: string;
    };
    pinecone: {
        key: string;
        env: string;
        index: string;
    };
}
export interface AccountData {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    username: string;
    createdAt?: number;
    updatedAt?: number;
    stripe_customer_id?: string;
    // default_memory_created?: string;
    // rlhf_encryption_key?: string;
    uui_subscription_status?: "active" | "expired" | "processing" | "inactive";
    backend_subscription_status?:
    | "active"
    | "expired"
    | "processing"
    | "inactive";
    backend_member?: "yes" | "no";
}

declare type SubscriptionStatus =
    | "not_fetching"
    | "loading"
    | "fetched_error"
    | "fetched";

export interface AccountState {
    isAccountLoading: boolean;
    // apiKeys: AccountApiKeys;
    accountExists: boolean;
    data: AccountData;
    subscription: SubscriptionModel | null;
    subscriptionStatus: SubscriptionStatus;
    creditBalance: number;
    activeOrganizationId: string;
}

const initialState: AccountState = {
    isAccountLoading: true,
    accountExists: false,
    activeOrganizationId: window.sessionStorage.getItem("active_org") || "",
    data: {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        username: ""
    },
    subscription: null,
    subscriptionStatus: "not_fetching",
    creditBalance: 0
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        setUserAccountLoading(state, action: PayloadAction<boolean>) {
            state.isAccountLoading = action.payload;
        },
        setUserAccountExists(state, action: PayloadAction<boolean>) {
            state.accountExists = action.payload;
        },
        setAccountCredentials(state, actions: PayloadAction<AccountData>) {
            state.data = actions.payload;
        },
        setAccountSubscriptionData(
            state,
            actions: PayloadAction<SubscriptionModel>
        ) {
            state.subscription = actions.payload;
        },
        clearSubscriptionData(state) {
            state.subscription = null;
        },
        setSubscriptionStatus(
            state,
            actions: PayloadAction<SubscriptionStatus>
        ) {
            state.subscriptionStatus = actions.payload;
        },
        setUserCreditBalance(state, actions: PayloadAction<number>) {
            state.creditBalance = actions.payload;
        },
        setActiveOrganizationId(state, actions: PayloadAction<string>) {
            state.activeOrganizationId = actions.payload;
        }
    }
});
export const {
    setAccountCredentials,
    setUserAccountExists,
    setUserAccountLoading,
    setAccountSubscriptionData,
    clearSubscriptionData,
    setSubscriptionStatus,
    setUserCreditBalance,
    setActiveOrganizationId
} = accountSlice.actions;
export default accountSlice.reducer;
