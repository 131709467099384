import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { type Socket } from "socket.io-client";
import BrainActivity from "../components/waiting-room/BrainActivity";
import "../styles/WaitingRoom.component.scss";
import ProfileDropdown from "../components/ProfileDropdown";
import RoomMessageCard from "../components/RoomMessageCard";
import RoomMessageInput from "../components/RoomMessageInput";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ViewLayoutComponent } from "../components/ViewLayout.component";
import { useHttpClient } from "../utils/http.utils";
import { LordIcon } from "../components/icons/LordIcon";
import { CiCircleInfo } from "react-icons/ci";
import { TemporaryRecords } from "../components/TemporaryRecords";
import RoomAudioMessageCard from "../components/RoomAudioMessageCard";
import RoomVideoMessageCard from "../components/RoomVideoMessageCard";
import {
    type FileObject,
    type IRoomParticipant,
    type RoomGroupModel,
    type RoomMessage
} from "../models/room.model";
import { useDispatch, useSelector } from "react-redux";
// import { GroupsState } from "../store/slices/groups.slice";
import {
    type RoomsState,
    selectActiveRoomId,
    setActiveRoomFileList,
    setActiveRoomId,
    setWatchingCollabIdList
} from "../store/slices/rooms.slice";
import {
    type RoomMessageWithLoading,
    addMessage,
    clearMessages,
    confirmMessage,
    setMessages,
    updateMessageLoading
} from "../store/slices/message.slice";
import generateUUID from "../utils/collaborate/generateUUID";
import { sortListByKey } from "../utils/array.utils";
import { setBrainScanPersonas } from "../store/slices/brain-scan.slice";
import { initSocket } from "../utils/socket.utils";
import toast from "react-hot-toast";
import { getFileLink } from "../utils/collaborate/parser";
import { type PersonaState } from "../store/slices/persona.slice";
import { LuChevronsUpDown } from "react-icons/lu";
import { getPersonaFullName } from "../utils/strings.utils";
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
    Input,
    Spinner
} from "@chakra-ui/react";
import { formatDate } from "../utils/date.utils";
import { debounce } from "lodash";
import { ROUTES } from "../routes";
import LoadingMessagesComponent from "../components/LoadingMessagesComponent";

const RoomView = () => {
    const dispatch = useDispatch();
    const messages: RoomMessageWithLoading[] = useSelector(
        (state: any) => state.messages
    );
    const { id, personaId } = useParams();
    // First check if room type is a group
    // const isGroup = window.location.pathname.includes("/room-group/");
    const activeRoomId = useSelector(selectActiveRoomId);
    const personaState: PersonaState = useSelector(
        (state: any) => state.personas
    );
    // const groupsState: GroupsState = useSelector((state: any) => state.groups);
    const [roomData, setRoomData] = useState<RoomGroupModel>();
    const socketRef = useRef<Socket | null>(null);
    const selectedPersona = useMemo(
        () => personaState.personas.find(p => p.id === personaId),
        [personaState.personas]
    );
    const { data: loggedInUser } = useSelector((state: any) => state.account);
    const localIDsSent = useRef<Set<string>>(new Set());
    const [roomParticipants, setRoomParticipants] = useState<
    IRoomParticipant[]
    >([]);
    const drawerDisclosure = useDisclosure();
    const [isLoadingPersonaRooms, setIsLoadingPersonaRooms] =
        useState<boolean>(false);
    const roomsState: RoomsState = useSelector((state: any) => state.rooms);

    const {
        listRoomMessageApi,
        createRoomMessageApi,
        addVideoToRoomMessageApi,
        addAudioToRoomMessageApi,
        addMessageToHistoryApi,
        listFileApi,
        listPersonaPortalsApi
    } = useHttpClient();

    const messagesRef = useRef(messages);
    const navigate = useNavigate();

    messagesRef.current = messages;
    // const navigate = useNavigate();
    const messagesContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchFileList = async () => {
            const fileList = await listFileApi({ room: activeRoomId });

            dispatch(setActiveRoomFileList(fileList));
        };

        if (activeRoomId) {
            fetchFileList();
        }
    }, [dispatch, activeRoomId]);

    useEffect(() => {
        if (id) {
            fetchRoomData(id);
        }
    }, [personaState.personaPortals]);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = initSocket();
        }

        socketRef.current.connect();

        if (socketRef.current) {
            socketRef.current.on(`room-message-creation-${id}`, data => {
                // change nature of user id so it can be matched and showed on the right side
                const incomingMessage = { ...data.room_message };

                // Check if the message ID is in the messages you have sent
                const existingMessageByID = messagesRef.current.find(
                    msg => msg.message.id === incomingMessage.id
                );

                // If it exists, it's an acknowledgment from the server
                if (existingMessageByID) {
                    dispatch(
                        updateMessageLoading({
                            id: incomingMessage.id,
                            loading: false
                        })
                    );

                    if (localIDsSent.current.has(existingMessageByID.localID)) {
                        dispatch(
                            confirmMessage({
                                localID: existingMessageByID.localID,
                                confirmedID: incomingMessage.id
                            })
                        );
                        localIDsSent.current.delete(
                            existingMessageByID.localID
                        ); // Remove the localID since it's now confirmed by the server
                    }
                } else {
                    const newMessage = {
                        message: {
                            ...incomingMessage,
                            user: {
                                id: data.room_message.user
                            }
                        },
                        loading: false,
                        localID: generateUUID() // Generate a new localID for this incoming message
                    };
                    dispatch(addMessage(newMessage));
                }
            });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.removeAllListeners();
                socketRef.current.disconnect();
            }
        };
    }, [dispatch]);

    useEffect(() => {
        if (messagesContainerRef.current) {
            const { scrollHeight, clientHeight } = messagesContainerRef.current;
            const scrollPosition = scrollHeight - clientHeight;
            messagesContainerRef.current.scrollTo(0, scrollPosition);
        }
    }, [messages]);

    const fetchRoomData = async (id: string) => {
        // Clear messages
        dispatch(clearMessages());
        // Get room data from state
        // Then get the room data

        // if (groupsState.data && roomsState.data) {
        if (personaState.personaPortals) {
            const room = personaState.personaPortals.find(
                data => data.id === id
            );
            let personaParticipants: IRoomParticipant[] = [];
            let userParticipants: IRoomParticipant[] = [];

            if (room) {
                // Set the active room
                dispatch(setActiveRoomId(room.id));
                if (room.persona_member_list) {
                    personaParticipants = room.persona_member_list.map(
                        personaMember => ({
                            id: personaMember.persona.id!,
                            member_id: personaMember.id,
                            name:
                                personaMember.persona?.first_name +
                                " " +
                                personaMember.persona?.last_name,
                            profile_picture:
                                personaMember.persona?.profile_picture,
                            type: "persona",
                            persona_type: personaMember.persona?.type
                        })
                    );
                    dispatch(
                        setBrainScanPersonas(
                            room.persona_member_list.map(
                                personaMember => personaMember.persona
                            )
                        )
                    );
                }

                if (room.user_member_list) {
                    userParticipants = room.user_member_list.map(
                        userMember => ({
                            id: userMember?.user?.id,
                            member_id: userMember?.id,
                            name:
                                userMember?.user?.name ||
                                userMember?.user?.email,
                            profile_picture: userMember?.user?.profile_picture,
                            type: "user"
                        })
                    );
                }
                setRoomParticipants(
                    sortListByKey<IRoomParticipant>(
                        [...(personaParticipants || []), ...userParticipants],
                        "name"
                    )
                );

                // set data
                setRoomData(room);
                // Fetch messages from server
                const fetchMessages = async () => {
                    // Do not include threaded messages
                    const fetchedMessages = await listRoomMessageApi({
                        room: id,
                        or: [{ parent_id: null }, { parent_id: "" }]
                    });
                    const collabMessages: RoomMessage[] =
                        await listRoomMessageApi({
                            room: id,
                            collaboration_app: { "!=": null }
                        });

                    const messagesWithLoading: RoomMessageWithLoading[] =
                        fetchedMessages.map((message: RoomMessage) => ({
                            message,
                            loading: false,
                            localID: message.id!
                        }));

                    dispatch(setMessages(messagesWithLoading));
                    dispatch(
                        setWatchingCollabIdList(
                            collabMessages.map(
                                message => message.collaboration_app!
                            )
                        )
                    );
                };

                fetchMessages();
            }
        }
    };

    const isGroup = () => roomData?.is_single !== "yes";

    const onVoiceRecorded = async (blob: Blob) => {
        try {
            const response = await addAudioToRoomMessageApi(blob);

            if (response) {
                await createRoomMessageApi({
                    room: id,
                    user: loggedInUser?.id,
                    message_type: "voice",
                    message_data: `${process.env.REACT_APP_API_URL}/api/v1/room-message/show-audio-media/${response?.data?.saved_filename}/${response?.data?.extension}`,
                    tagged_member_list: []
                });
            }
        } catch (error) {
            console.error("Failed to upload audio:", error);
        }
    };

    const onVideoRecorded = async (blob: Blob) => {
        try {
            const localID = generateUUID();
            localIDsSent.current.add(localID);

            const toastId = toast.loading("Uploading video, Please wait..", {
                duration: 9000000000000
            });
            // upload video to server,
            const response = await addVideoToRoomMessageApi(
                blob,
                "recorded-video.webm"
            );
            // upload to server
            if (response) {
                // Create a new message object
                const messageToSend: RoomMessageWithLoading = {
                    message: {
                        room: id,
                        user: loggedInUser?.id,
                        message_type: "video",
                        message_data: response?.data.video_id,
                        tagged_member_list: [],
                        // tagged_user_list: [],
                        type: "room_message"
                    },
                    loading: true,
                    localID
                };
                await createRoomMessageApi(messageToSend.message);
            }

            // remove loading
            toast.dismiss(toastId);
        } catch (error) {
            console.error("Failed to upload video:", error);
        }
    };

    const onText = async ({ message, taggedMemberList }) => {
        const localID = generateUUID();
        localIDsSent.current.add(localID);
        // Create a new message object
        const messageToSend: RoomMessageWithLoading = {
            message: {
                room: id,
                user: loggedInUser?.id,
                message_type: "text",
                message_data: message,
                tagged_member_list:
                    roomData?.is_single === "yes"
                        ? [roomData.persona_member_list[0].id]
                        : taggedMemberList,
                record_url: "",
                record_file: "",
                type: "room_message"
            },
            loading: true,
            localID
        };
        // dispatch(addMessage(messageToSend));
        try {
            const response = await createRoomMessageApi(messageToSend.message);

            const confirmedID = response.room_message.id;

            if (confirmedID) {
                dispatch(
                    confirmMessage({
                        localID,
                        confirmedID
                    })
                );
            } else {
                console.error(
                    "Error: No confirmed ID received from the server."
                );
            }
        } catch (error) {
            console.error("Error sending the text message:", error);
        }
    };

    const ratePersonaResponse = async (message: RoomMessage) => {
        const data = await addMessageToHistoryApi({
            message: message.message_data,
            messageId: message.id,
            userId: loggedInUser.id,
            personaId: loggedInUser.id // TODO: CHANGE TO PERSONA ID, THIS IS DONE FOR TESTING PURPOSES
        });

        if (data === false) {
            // Send a status to the message component that the message has been rated
            return false;
        } else {
            // Send a status to the message component that the message has not been rated
            return true;
        }
    };

    const openPersonaCardsModal = async () => {
        drawerDisclosure.onOpen();
    };

    /**
     * Debounce search
     */
    const debouncedSearch = useCallback(
        debounce((value: string) => {
            const searchQuery: any = { personaId };

            if (value) {
                searchQuery.userEmail = value.trim().toLowerCase();
            }

            setIsLoadingPersonaRooms(true);
            listPersonaPortalsApi(searchQuery).finally(() =>
                setIsLoadingPersonaRooms(false)
            );
        }, 500),
        []
    );

    const loadRoomPortal = (id: string) => {
        // replace url
        navigate(
            ROUTES.PERSONA_ROOM_CHAT.replaceAll(":id", id).replaceAll(
                ":personaId",
                personaId!
            )
        );

        fetchRoomData(id);
        drawerDisclosure.onClose();
    };

    return (
        <ViewLayoutComponent>
            <div className="waiting-room">
                <div className="grid-column bg-white pt-[12px]">
                    <ProfileDropdown dropDownStyle={{ boxShadow: "none" }} />
                    {/* <WaitingLeftPane /> */}
                    <button
                        onClick={openPersonaCardsModal}
                        className="select w-full border rounded-lg p-2 mt-4 flex justify-between items-center"
                    >
                        <p className="text-[14px] truncate">
                            {roomData?.user_member_list
                                .map(
                                    member =>
                                        member.user?.first_name +
                                        " " +
                                        member.user?.last_name +
                                        " (" +
                                        member.user?.email +
                                        ")"
                                )
                                .join(", ")}
                        </p>
                        <span>
                            <LuChevronsUpDown size={"20px"} />
                        </span>
                    </button>
                    <div className="pt-[20px] temporary-record-holder persona-view">
                        <TemporaryRecords />
                    </div>
                </div>
                <div className="main-grid grid-column">
                    <div className="main-grid-wrapper">
                        <div className="waiting-room-header-box">
                            <div className="header-box">
                                <h2 className="title">
                                    {roomData?.title}
                                    <p className="title-description truncate">
                                        {roomData?.description}
                                    </p>
                                </h2>
                                <div className="subtitle flex w-full overflow-hidden truncate">
                                    {/* <p className="name">{roomData?.owner.email}</p> */}

                                    {roomParticipants
                                        .slice(0, 3)
                                        .map((participant, index) => {
                                            return (
                                                <span
                                                    key={"participant_" + index}
                                                    className="name whitespace-nowrap"
                                                >
                                                    {index > 0 && ","}&nbsp;
                                                    {participant.name}{" "}
                                                    {participant.type !==
                                                        "user" && (
                                                        <span className="arin-text">
                                                            {
                                                                participant.persona_type
                                                            }
                                                        </span>
                                                    )}
                                                </span>
                                            );
                                        })}
                                    {roomParticipants.length > 4 && (
                                        <span>
                                            & ${roomParticipants.length - 4}{" "}
                                            others
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="line w-full">
                                <div className="line-inner"></div>
                            </div>
                            <div className="actions-box">
                                <NavLink to={"/"}>
                                    <button className="button">
                                        <LordIcon
                                            src="/lord-icons/stack.json"
                                            trigger="hover"
                                            colors={{
                                                primary: "#121331",
                                                secondary: "#333"
                                            }}
                                            stroke={40}
                                            size={30}
                                        />
                                    </button>
                                </NavLink>

                                <button className="button">
                                    <CiCircleInfo
                                        className="text-gray-600"
                                        size="22px"
                                    />
                                </button>
                            </div>
                        </div>
                        {/* <div className="waiting-room-header-content lg-view">
                        <NavLink to={'/'}>
                            <button className="back-button">
                                <LordIcon
                                    src="/lord-icons/stack.json"
                                    trigger="hover"
                                    colors={{
                                        primary: '#121331',
                                        secondary: '#333',
                                    }}
                                    stroke={40}
                                    size={30}
                                />
                            </button>
                        </NavLink>
                        <div className="divider"></div>
                        <p className="">
                            with montague adameve (arin), paul snow, james
                            duchenne & 235 others.
                        </p>
                    </div> */}
                        <div
                            className="waiting-room-body-content"
                            ref={messagesContainerRef}
                        >
                            {/* <RoomMessageCard
                                mode="info"
                                text={[
                                    "Welcome to Neo Al's VIP room. Stay up to date with Al while we roll out Neo. #FollowTheWhiteRabbit",
                                ]}
                            /> */}
                            {roomsState.isLoadingMessages && (
                                <LoadingMessagesComponent />
                            )}

                            {messages.map((_, index, arr) => {
                                const item = arr[arr.length - 1 - index];
                                const message = item.message;

                                const isOwnMessage =
                                    message?.user?.id === loggedInUser?.id;

                                if (message.message_type === "text") {
                                    return (
                                        <div key={message.id}>
                                            {message.message_data && (
                                                <RoomMessageCard
                                                    messageId={message.id}
                                                    questionId={
                                                        message.question_id
                                                    }
                                                    roomId={id}
                                                    text={[
                                                        message.message_data
                                                    ]}
                                                    isInGroup={isGroup()}
                                                    isOwnMessage={isOwnMessage}
                                                    fileObject={
                                                        (
                                                            message.file_object as FileObject
                                                        )?.id
                                                            ? {
                                                                name: (
                                                                    message.file_object as FileObject
                                                                ).name,
                                                                link: getFileLink(
                                                                      message.file_object as FileObject
                                                                )
                                                            }
                                                            : undefined
                                                    }
                                                    isRated={message.isRated}
                                                    loading={item.loading}
                                                    otherProps={message}
                                                    auto_interaction_message_type={
                                                        message.auto_interaction_message_type
                                                    }
                                                    onRateResponse={async () =>
                                                        await ratePersonaResponse(
                                                            message
                                                        )
                                                    }
                                                    date={message.createdAt}
                                                />
                                            )}
                                        </div>
                                    );
                                } else if (message.message_type === "video") {
                                    return (
                                        <div key={message.id}>
                                            <RoomVideoMessageCard
                                                videoId={Number(
                                                    message.message_data
                                                )}
                                                isOwnMessage={isOwnMessage}
                                                loading={item.loading}
                                                messageId={message.id}
                                                roomId={id}
                                                isInGroup={isGroup()}
                                                otherProps={message}
                                                onRateResponse={async () =>
                                                    await ratePersonaResponse(
                                                        message
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                } else if (message.message_type === "voice") {
                                    return (
                                        <div key={message.id}>
                                            <RoomAudioMessageCard
                                                isOwnMessage={isOwnMessage}
                                                audioSrc={message.message_data!}
                                                loading={item.loading}
                                                messageId={message.id}
                                                roomId={id}
                                                isInGroup={isGroup()}
                                                otherProps={message}
                                                onRateResponse={async () =>
                                                    await ratePersonaResponse(
                                                        message
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={message.id}>
                                            <></>
                                        </div>
                                    );
                                }
                            })}

                            <RoomMessageInput
                                room={roomData}
                                disableTagging={roomData?.is_single === "yes"}
                                isGroup={isGroup()}
                                showActions={["write", "voice", "video"]}
                                onVoice={onVoiceRecorded}
                                onVideo={onVideoRecorded}
                                onText={onText}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid-column bg-white activity-grid pt-[12px]">
                    <BrainActivity />
                </div>
            </div>
            {/* Darwer component */}
            <Drawer
                isOpen={drawerDisclosure.isOpen}
                placement="left"
                onClose={drawerDisclosure.onClose}
            >
                <DrawerOverlay className="w-[16px]" />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader py="2" px="3" borderBottom={"1px solid #ddd"}>
                        <p className="text-[15px] font-[400]">
                            {selectedPersona
                                ? getPersonaFullName(selectedPersona)
                                : ""}{" "}
                            Portals
                        </p>
                    </DrawerHeader>

                    <DrawerBody pb="3" px="0">
                        <div className="h-full flex flex-col overflow-hidden">
                            <div className="pb-2 min-h-[40px] px-4">
                                <div className="input-group relative">
                                    <Input
                                        placeholder="Search.."
                                        size={"sm"}
                                        onInput={e =>
                                            debouncedSearch(
                                                (e.target as any)
                                                    .value as string
                                            )
                                        }
                                    />
                                    {isLoadingPersonaRooms && (
                                        <p className="absolute right-2 top-1/2 -translate-y-1/2">
                                            <Spinner size="sm" />
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="flex-grow overflow-y-auto px-2">
                                {personaState.personaPortals.map(portal => {
                                    const memberList = portal.user_member_list
                                        .map(
                                            member =>
                                                member.user?.first_name +
                                                " " +
                                                member.user?.last_name +
                                                " (" +
                                                member.user?.email +
                                                ")"
                                        )
                                        .join(", ");
                                    return (
                                        <div
                                            key={portal.id}
                                            className={`border-b px-4 rounded flex justify-between py-2 mb-2 ${
                                                roomData?.id === portal.id
                                                    ? "bg-blue-50"
                                                    : ""
                                            }`}
                                        >
                                            <div className="max-w-[80%]">
                                                <h3 className="m-0 text-[14px] font-[300px]">
                                                    {portal.title}
                                                </h3>
                                                <p className="m-0 text-neutral-500 truncate">
                                                    with&nbsp;
                                                    <span
                                                        className="text-blue-500 mr-1"
                                                        title={memberList}
                                                    >
                                                        {memberList}
                                                    </span>
                                                </p>
                                                <p className="m-0 text-neutral-500 truncate">
                                                    {portal.description ||
                                                        `Created on ${formatDate(
                                                            portal.createdAt!
                                                        )}`}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                {
                                                    <Button
                                                        onClick={() =>
                                                            loadRoomPortal(
                                                                portal.id
                                                            )
                                                        }
                                                        variant={"link"}
                                                        size={"sm"}
                                                        className="text-blue-500"
                                                    >
                                                        Drop In
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                                {personaState.personaPortals.length === 0 &&
                                    !isLoadingPersonaRooms && (
                                    <div className="h-[300px] w-full flex justify-center items-center">
                                        <p className="text-neutral-500">
                                                No portals found
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </ViewLayoutComponent>
    );
};

export default RoomView;
