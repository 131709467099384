import "../styles/RoomMessageCard.component.scss";
import React, { useEffect, useState } from "react";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { formatDate } from "../utils/date.utils";
import {
    Button,
    ButtonGroup,
    SkeletonCircle,
    useToast
} from "@chakra-ui/react";
import { TbLayoutNavbarExpand } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { convertHalfCharactersToBold } from "../utils/strings.utils";
import { LordIcon } from "./icons/LordIcon";
import { LuCopy, LuBookmark, LuLink } from "react-icons/lu";
import SaveResponseForm from "./SaveResponsesForm";
import {
    addChainItem,
    selectActivePersonaId,
    selectPersonaAnswerIdSet
} from "../store/slices/brain-scan.slice";
import { useDispatch, useSelector } from "react-redux";
import { useHttpClient } from "../utils/http.utils";
import { type RoomMessage } from "../models/room.model";

interface IRoomMessageCardProps {
    title?: string;
    fileObject?: { name: string; link: string };
    text: string[];
    messageId?: string;
    isRated?: boolean;
    roomId?: string;
    questionId?: string;
    isInGroup: boolean;
    trailingContent?: React.ReactNode;
    mode?: "info" | "default";
    hideThread?: boolean;
    isOwnMessage?: boolean;
    loading?: boolean;
    otherProps?: any;
    auto_interaction_message_type?: RoomMessage["auto_interaction_message_type"];
    lastDecisionMessageId?: string;
    onRateResponse?: () => Promise<boolean>;
    onSendExecutionCommand?: (
        decisionMessageType: RoomMessage["auto_interaction_message_type"],
        decision: "yes" | "no"
    ) => Promise<void>;
    date?: number;
}

const RoomMessageCard = (props: IRoomMessageCardProps) => {
    const [isBoltMode, setIsBoltMode] = useState(false);
    const [threadCount, _setThreadCount] = useState<number>(0);
    const [sentiment, setSentiment] = useState(props.isRated ? "1" : "");
    const [hasImageLoaded, setHasImageLoaded] = useState(false);
    const activePersonaId = useSelector(selectActivePersonaId);
    const personaAnswerIdSet = useSelector(selectPersonaAnswerIdSet);
    const { user } = useSelector((state: any) => state.auth);
    const toast = useToast();
    const dispatch = useDispatch();
    const [showSaveModal, setShowSaveModal] = useState(false);
    const { listRoomMessageApi } = useHttpClient();
    const [messageAuthor, setMessageAuthor] = useState<{
        name: string;
        profile_picture?: string;
        type: string;
    }>();

    const cardMode = props.mode || "default";

    const changeSentiment = (type: string) => {
        setSentiment(type);
    };

    const containerClass = props.isOwnMessage
        ? "room-card-container own-message"
        : "room-card-container";

    useEffect(() => {
        if (props.isOwnMessage) {
            setMessageAuthor({
                name: user.name,
                profile_picture: user.profile_picture,
                type: "user"
            });
        } else if (props.otherProps?.user) {
            setMessageAuthor({
                name: props.otherProps.user.name,
                profile_picture: props.otherProps.user.profile_picture,
                type: "user"
            });
        } else if (props.otherProps?.persona) {
            setMessageAuthor({
                name:
                    props.otherProps.persona.first_name +
                    " " +
                    props.otherProps.persona.last_name,
                profile_picture: props.otherProps.persona.profile_picture,
                type: "persona"
            });
        }
    }, []);

    /**
     *
     * Copy the reply to clipboard
     */
    const copyToClipBoard = (data: string) => {
        navigator.clipboard
            .writeText(data)
            .then(() => {
                toast({
                    title: "Copied",
                    duration: 1000,
                    status: "info"
                });
            })
            .catch(() => {
                toast({
                    title: "Can not copy to clipboard",
                    duration: 1000,
                    status: "error"
                });
            });
    };

    /**
     * When the thumbs up button is clicked
     */
    const ratePersonaResponse = async () => {
        if (typeof props.onRateResponse === "function") {
            const status = await props.onRateResponse();
            setSentiment("1");
            // If the status from the parent is false, change it back to a button
            if (!status) {
                setSentiment("");
            }
        }
    };

    const addMessageToChain = async () => {
        if (props.messageId && props.questionId && props.roomId) {
            const questionMessage: RoomMessage = (
                await listRoomMessageApi({
                    room: props.roomId,
                    id: props.questionId
                })
            )?.[0];

            if (questionMessage.message_data) {
                dispatch(
                    addChainItem({
                        personaId: activePersonaId,
                        answerContent: props.text.join("\n"),
                        answerMessageId: props.messageId,
                        questionMessageId: props.questionId,
                        questionContent: questionMessage.message_data
                    })
                );
            }
        }
    };

    const shouldShowControlButtons = () =>
        (props.auto_interaction_message_type === "execute_next_cell" ||
            props.auto_interaction_message_type === "execute_tool") &&
        props.lastDecisionMessageId === props.messageId;

    return (
        <div className={containerClass}>
            {props.isOwnMessage ? (
                <>
                    <div className="trailing">{props.trailingContent}</div>
                    <div className="message-box">
                        {props.title && (
                            <h2 className="font-bold mb-[5px]">
                                {props.title}
                            </h2>
                        )}
                        {props.text.map((text, index) => (
                            <p
                                key={`card-response-${index} whitespace-pre-wrap`}
                                className="card-response"
                                dangerouslySetInnerHTML={{
                                    __html: isBoltMode
                                        ? convertHalfCharactersToBold(text)
                                        : text
                                }}
                            />
                        ))}
                        {cardMode !== "info" && (
                            <div className="card-footer mt-[10px] md:mt-[16px]">
                                <div className="date flex md:block">
                                    {/* <p className="card-date text-slate-400">{capitalizeWord(getMemoryFromNamespaceString(props.data.check_list as string).sector || '')}</p> */}
                                    <p className="card-date text-slate-400">
                                        {props.isOwnMessage
                                            ? "You"
                                            : props.otherProps?.display_name}
                                    </p>
                                    <p className="card-date text-slate-400">
                                        {formatDate(
                                            props.date || "",
                                            "D MMMM YYYY"
                                        )}
                                    </p>
                                </div>
                                <div className="card-footer-actions">
                                    {/* {sentiment === "1" && (
                                        <p className="sentiment-message gap-1.5 pr-4 text-slate-500">
                                            <FiThumbsUp className="icon positive" />
                                            <span>You rated "Amazing"</span>
                                        </p>
                                    )}
                                    {sentiment === "0" && (
                                        <p className="sentiment-message flex items-center gap-1.5 pr-4 text-slate-500">
                                            <FiThumbsDown className="icon negative" />
                                            <span>You rated "Could be better"</span>
                                        </p>
                                    )} */}

                                    {/* {!sentiment && (
                                        <>
                                            <Button
                                                mr={1}
                                                className="icon-buttons"
                                                onClick={ratePersonaResponse}
                                                colorScheme="blue"
                                                size="sm"
                                                // variant={props.data.Sentiment === '1' ? 'solid' : 'outline'}
                                                variant="ghost"
                                            >
                                                <FiThumbsUp className="icon positive" />
                                            </Button>
                                            <Button
                                                mr={1}
                                                className="icon-buttons"
                                                onClick={() => changeSentiment("0")}
                                                colorScheme="red"
                                                size="sm"
                                                // variant={props.data.Sentiment === '0' ? 'solid' : 'outline'}
                                                variant="ghost"
                                            >
                                                <FiThumbsDown className="icon negative" />
                                            </Button>
                                        </>
                                    )} */}

                                    <Button
                                        mr={1}
                                        className="icon-buttons"
                                        onClick={() =>
                                            setIsBoltMode(!isBoltMode)
                                        }
                                        variant={isBoltMode ? "solid" : "ghost"}
                                        colorScheme={
                                            isBoltMode ? "yellow" : "gray"
                                        }
                                    >
                                        {/* <BsFillLightningChargeFill className="icon" /> */}
                                        <LordIcon
                                            src="/lord-icons/electric-power.json"
                                            trigger="click"
                                            colors={{
                                                primary: "#121331",
                                                secondary: "#333"
                                            }}
                                            stroke={40}
                                            size={36}
                                        />
                                    </Button>

                                    <Button
                                        mr={1}
                                        className="icon-buttons"
                                        colorScheme="gray"
                                        onClick={() =>
                                            copyToClipBoard(
                                                props.text.join("\n")
                                            )
                                        }
                                        variant="ghost"
                                    >
                                        <LuCopy className="icon" />
                                    </Button>
                                    <Button
                                        mr={1}
                                        className="icon-buttons"
                                        onClick={() => setShowSaveModal(true)}
                                        variant="ghost"
                                    >
                                        <LuBookmark className="icon save-icon" />
                                    </Button>

                                    {/* <Button mr={1} className="icon-buttons" colorScheme="gray" onClick={() => copyToClipBoard(props.text)} variant="ghost">
                                        <LuLink className="icon rotate" size="24px" />
                                    </Button> */}

                                    {!props.hideThread && (
                                        <NavLink
                                            to={
                                                props.isInGroup
                                                    ? `/room-group/${props.roomId}/thread/${props.messageId}`
                                                    : `/room/${props.roomId}/thread/${props.messageId}`
                                            }
                                        >
                                            {/* <NavLink to={`/room/${props.roomId}/thread/${props.messageId}`}> */}
                                            <Button
                                                title={
                                                    threadCount > 0
                                                        ? `Thread (${threadCount})`
                                                        : "Start thread"
                                                }
                                                colorScheme={
                                                    threadCount > 0
                                                        ? "brand"
                                                        : "gray"
                                                }
                                                size="sm"
                                                variant={
                                                    threadCount > 0
                                                        ? "solid"
                                                        : "outline"
                                                }
                                            >
                                                <TbLayoutNavbarExpand className="icon" />
                                                &nbsp;
                                                {threadCount > 0
                                                    ? `Thread (${threadCount})`
                                                    : "Start thread"}
                                            </Button>
                                        </NavLink>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="leading leading-end">
                        <img
                            src={user.profile_picture || "/user_avatar.png"}
                            className={`${hasImageLoaded ? "block" : "hidden"}`}
                            onLoad={() => setHasImageLoaded(true)}
                            alt=""
                        />
                        {!hasImageLoaded && <SkeletonCircle size="30" />}
                    </div>
                </>
            ) : (
                <>
                    <div className="leading">
                        <img
                            src={
                                messageAuthor?.profile_picture ||
                                "/user_avatar.png"
                            }
                            className={`${hasImageLoaded ? "block" : "hidden"}`}
                            onLoad={() => setHasImageLoaded(true)}
                            alt=""
                        />
                        {!hasImageLoaded && <SkeletonCircle size="30" />}
                    </div>
                    <div className="message-box">
                        {props.title && (
                            <h2 className="font-bold mb-[5px]">
                                {props.title}
                            </h2>
                        )}
                        {props.text.map((text, index) => (
                            <p
                                key={`card-response-${index}`}
                                className="card-response whitespace-pre-wrap"
                                dangerouslySetInnerHTML={{
                                    __html: isBoltMode
                                        ? convertHalfCharactersToBold(text)
                                        : text
                                }}
                            />
                        ))}
                        {props.fileObject && (
                            <>
                                <br />
                                <p className="card-response card-response-link">
                                    File:{" "}
                                    <a
                                        target="_blank"
                                        href={props.fileObject.link}
                                        download={props.fileObject.name}
                                        rel="noreferrer"
                                    >
                                        {props.fileObject.name}
                                    </a>
                                </p>
                            </>
                        )}
                        {cardMode !== "info" && (
                            <div className="card-footer mt-[10px] md:mt-[16px]">
                                <div className="date flex md:block">
                                    {/* <p className="card-date text-slate-400">{capitalizeWord(getMemoryFromNamespaceString(props.data.check_list as string).sector || '')}</p> */}
                                    <p className="card-date text-slate-400">
                                        {props.isOwnMessage
                                            ? "You"
                                            : props.otherProps?.display_name}
                                    </p>
                                    <p className="card-date text-slate-400">
                                        {formatDate(
                                            props.date || "",
                                            "D MMMM YYYY"
                                        )}
                                    </p>
                                </div>
                                <div className="card-footer-actions">
                                    {sentiment === "1" && (
                                        <p className="sentiment-message gap-1.5 pr-4 text-slate-500">
                                            <FiThumbsUp className="icon positive" />
                                            <span>You rated "Amazing"</span>
                                        </p>
                                    )}
                                    {sentiment === "0" && (
                                        <p className="sentiment-message flex items-center gap-1.5 pr-4 text-slate-500">
                                            <FiThumbsDown className="icon negative" />
                                            <span>
                                                You rated "Could be better"
                                            </span>
                                        </p>
                                    )}

                                    {!sentiment && (
                                        <>
                                            <Button
                                                mr={1}
                                                className="icon-buttons"
                                                onClick={ratePersonaResponse}
                                                colorScheme="blue"
                                                size="sm"
                                                // variant={props.data.Sentiment === '1' ? 'solid' : 'outline'}
                                                variant="ghost"
                                            >
                                                <FiThumbsUp className="icon positive" />
                                            </Button>
                                            <Button
                                                mr={1}
                                                className="icon-buttons"
                                                onClick={() =>
                                                    changeSentiment("0")
                                                }
                                                colorScheme="red"
                                                size="sm"
                                                // variant={props.data.Sentiment === '0' ? 'solid' : 'outline'}
                                                variant="ghost"
                                            >
                                                <FiThumbsDown className="icon negative" />
                                            </Button>
                                        </>
                                    )}

                                    <Button
                                        mr={1}
                                        className="icon-buttons"
                                        onClick={() =>
                                            setIsBoltMode(!isBoltMode)
                                        }
                                        variant={isBoltMode ? "solid" : "ghost"}
                                        colorScheme={
                                            isBoltMode ? "yellow" : "gray"
                                        }
                                    >
                                        {/* <BsFillLightningChargeFill className="icon" /> */}
                                        <LordIcon
                                            src="/lord-icons/electric-power.json"
                                            trigger="click"
                                            colors={{
                                                primary: "#121331",
                                                secondary: "#333"
                                            }}
                                            stroke={40}
                                            size={36}
                                        />
                                    </Button>

                                    <Button
                                        mr={1}
                                        className="icon-buttons"
                                        colorScheme="gray"
                                        onClick={() =>
                                            copyToClipBoard(
                                                props.text.join("/n")
                                            )
                                        }
                                        variant="ghost"
                                    >
                                        <LuCopy className="icon" />
                                    </Button>
                                    <Button
                                        mr={1}
                                        className="icon-buttons"
                                        onClick={() => setShowSaveModal(true)}
                                        variant="ghost"
                                    >
                                        <LuBookmark className="icon save-icon" />
                                    </Button>

                                    {props.questionId ? (
                                        <Button
                                            mr={1}
                                            className="icon-buttons"
                                            colorScheme={
                                                personaAnswerIdSet.has(
                                                    activePersonaId +
                                                        "-" +
                                                        props.messageId
                                                )
                                                    ? "blue"
                                                    : "gray"
                                            }
                                            onClick={async () =>
                                                await addMessageToChain()
                                            }
                                            variant={
                                                personaAnswerIdSet.has(
                                                    activePersonaId +
                                                        "-" +
                                                        props.messageId
                                                )
                                                    ? "outline"
                                                    : "ghost"
                                            }
                                        >
                                            <LuLink
                                                className="icon rotate"
                                                size="24px"
                                            />
                                        </Button>
                                    ) : (
                                        <></>
                                    )}

                                    {!props.hideThread && (
                                        <NavLink
                                            to={
                                                props.isInGroup
                                                    ? `/room-group/${props.roomId}/thread/${props.messageId}`
                                                    : `/room/${props.roomId}/thread/${props.messageId}`
                                            }
                                        >
                                            {/* <NavLink to={`/room/${props.roomId}/thread/${props.messageId}`}> */}
                                            <Button
                                                title={
                                                    threadCount > 0
                                                        ? `Thread (${threadCount})`
                                                        : "Start thread"
                                                }
                                                colorScheme={
                                                    threadCount > 0
                                                        ? "brand"
                                                        : "gray"
                                                }
                                                size="sm"
                                                variant={
                                                    threadCount > 0
                                                        ? "solid"
                                                        : "outline"
                                                }
                                            >
                                                <TbLayoutNavbarExpand className="icon" />
                                                &nbsp;
                                                {threadCount > 0
                                                    ? `Thread (${threadCount})`
                                                    : "Start thread"}
                                            </Button>
                                        </NavLink>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="trailing">{props.trailingContent}</div>
                    {shouldShowControlButtons() &&
                        props.onSendExecutionCommand && (
                        <div className="message-action col-start-2 text-right pt-2">
                            <ButtonGroup>
                                <Button
                                    onClick={async () =>
                                        await props.onSendExecutionCommand!(
                                                props.auto_interaction_message_type,
                                                "yes"
                                        )
                                    }
                                    size="sm"
                                    background="blue.50"
                                    color="blue.500"
                                >
                                        Proceed
                                </Button>
                                <Button
                                    onClick={async () =>
                                        await props.onSendExecutionCommand!(
                                                props.auto_interaction_message_type,
                                                "no"
                                        )
                                    }
                                    size="sm"
                                    background="red.50"
                                    color="red.500"
                                >
                                        Rerun Previous Cell
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                </>
            )}

            <SaveResponseForm
                isOpen={showSaveModal}
                onClose={() => setShowSaveModal(false)}
                id={props.messageId!}
            />
        </div>
    );
};

export default RoomMessageCard;
